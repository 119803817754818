<script lang="ts">
  // Note: Number of columns must match Picture[sizes]

  import type { Card as GridCard } from "./types/shared";
  import Card from "./Card.svelte";
  import { breakpoints as br } from "$lib/utils/getBreakpoints";

  type optionalFields = {
    date?: boolean;
    lead?: boolean;
  };

  interface Props {
    items?: GridCard[] | null;
    containerElement?: string;
    microformats?: boolean; // https://developer.mozilla.org/en-US/docs/Web/HTML/microformats#h-feed
    prePath?: string;
    displayFields?: optionalFields;
    horizontalScroll?: boolean;
    fixedHeight?: boolean;
  }

  let {
    items = null,
    containerElement = "ul",
    microformats = false,
    prePath = "/",
    displayFields = {},
    horizontalScroll = false,
    fixedHeight = false,
  }: Props = $props();

  let fields = $derived({
    ...{
      lead: true,
      date: false,
    },
    ...displayFields,
  });
</script>

<div class:horizontal-scroll={horizontalScroll}>
  {#if items?.length}
    <svelte:element this={containerElement} class="items">
      {#each items as item}
        <li class="block" class:h-entry={microformats}>
          <Card
            showButton={false}
            {microformats}
            href={`${prePath}${item.slug.current}${
              item.searchParams ? `?${item.searchParams.join("&")}` : ""
            }`}
            pictureObject={{
              src: item?.imgSrc,
              srcset: item?.imgSrcset,
              srcsetWebp: item?.webpSrcset,
              alt: item?.image?.alt,
              width: item?.image?.asset?.metadata?.dimensions?.width,
              height: item?.image?.asset?.metadata?.dimensions?.height,
            }}
            imgSizes={`(min-width: ${br.desktop}) 20vw, (min-width: ${br.landscape}) 45vw, 92vw`}
            date={fields?.date && item.publishedAt ? item.publishedAt : null}
            title={item.title}
            textHeight={horizontalScroll && fixedHeight ? "var(--card-text-height)" : null}
          >
            {#if fields?.lead && item.lead}
              <p class="p-summary m-0">{item.lead}</p>
            {/if}
          </Card>
        </li>
      {/each}
    </svelte:element>
  {/if}
</div>

<style lang="postcss">
  .items {
    gap: var(--s-16) var(--gap-inline);
    list-style: none;
    padding: 0;
  }

  div:not(.horizontal-scroll) .items {
    display: grid;
    margin-inline: var(--space-edge-x);
  }

  @media (--landscape) {
    div:not(.horizontal-scroll) .items {
      grid-template-columns: repeat(var(--card-grid-cols, 2), minmax(0, 1fr));
    }
  }

  @media (--desktop) {
    div:not(.horizontal-scroll) .items {
      --card-grid-cols: 3;
    }
  }

  /* Horizontal scroll specific styling */

  div.horizontal-scroll {
    --card-text-height: 10.5em;
    --_w-card-adjust: var(--gap-inline);
    --_w-card: calc(var(--w-col) * var(--card-span-cols, 2) - var(--_w-card-adjust));
    --_w-edge-x: calc(var(--space-edge-x) - var(--gap-inline));

    min-height: calc(var(--card-text-height) + var(--_w-card) / 16 * 9 + 5rem);
  }

  div.horizontal-scroll .items {
    display: flex;
    overflow-x: auto;
    padding-inline-end: calc(var(--gap-inline) / 2);
    padding-block: 3px var(--space); /* padding makes room for outline and horizontal-scroll bar */
    scroll-snap-type: x mandatory;
    scroll-padding-inline: var(--space-edge-x);
  }

  div.horizontal-scroll .items > :global(*) {
    display: block;
    scroll-snap-align: start;
    min-width: var(--_w-card);
  }

  div.horizontal-scroll .items::before,
  div.horizontal-scroll .items::after {
    content: "";
    display: block;
    width: var(--_w-edge-x);
  }

  @media (--landscape) {
    div.horizontal-scroll {
      --card-span-cols: 4;
    }
  }

  @media (--tablet) {
    div.horizontal-scroll {
      --_w-card-adjust: 0;
      --card-span-cols: 2;

      min-height: calc(
        var(--card-text-height) + (100vw - 2 * var(--space-edge-x) - 2 * var(--gap-inline)) / 3 /
          16 * 9 + 2.5rem
      );
    }

    div.horizontal-scroll .items {
      display: grid;
      grid-template-columns: var(--_w-edge-x) 1fr 1fr 1fr var(--_w-edge-x);
      padding-inline-end: 0;
      padding-block-end: 3px; /* padding makes room for outline, but not horizontal-scroll bar anymore */
    }
  }
</style>
