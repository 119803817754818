<script lang="ts">
  import type { ArticleCard } from "./types/shared";
  import GridCards from "./GridCards.svelte";

  type optionalFields = {
    date?: boolean;
    lead?: boolean;
  };

  interface Props {
    class?: string;
    rootUrl?: string;
    listTitle?: string | null;
    listTitleLevel?: 1 | 2 | 3 | 4 | 5 | 6;
    listTitleSize?: string | null /* CSS font-size value */;
    articles?: ArticleCard[] | null;
    displayFields?: optionalFields;
    horizontalScroll?: boolean;
    fixedHeight?: boolean;
    microformats?: boolean; // https://developer.mozilla.org/en-US/docs/Web/HTML/microformats#h-feed
  }

  let {
    class: classes = "",
    rootUrl = "/om/aktuelt/",
    listTitle = null,
    listTitleLevel = 1,
    listTitleSize = null,
    articles = [],
    displayFields = {},
    horizontalScroll = false,
    fixedHeight = false,
    microformats = false,
  }: Props = $props();
</script>

<svelte:element
  this={listTitle ? "section" : "div"}
  class="list-section {classes}"
  class:h-feed={microformats}
>
  {#if listTitle}
    <svelte:element
      this={`h${listTitleLevel}`}
      class="list-title p-name mx-edge-x"
      style={listTitleSize ? `font-size: ${listTitleSize}` : undefined}
    >
      {listTitle}
    </svelte:element>
  {/if}

  {#if !articles}
    <p class="mx-edge-x">Feil ved lasting av artikler</p>
  {:else}
    <GridCards
      {microformats}
      items={articles}
      {displayFields}
      {horizontalScroll}
      {fixedHeight}
      prePath={rootUrl}
    />
  {/if}
</svelte:element>

<style lang="postcss">
  /* Title */
  .list-section :global(.list-title) {
    font-family: var(--list-title-font, inherit);
  }

  .list-section :global(h1.list-title) {
    font-family: var(--list-title-font, var(--font-title));
  }
</style>
